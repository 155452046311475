import { inject, Injectable } from '@angular/core';
import { BookEvent, BookEventGTMService } from '@uc/web/core';
import { Product } from '@uc/web/shared/data-models';
import { BookEventBtnApiService } from './book-event-btn-api.service';

@Injectable({
	providedIn: 'root',
})
export class BookEventBtnService {
	private _bookEventGTMSrv = inject(BookEventGTMService);
	private _bookEventBtnApiSrv = inject(BookEventBtnApiService);

	trackEventClick(args: BookEvent, product: Product, eventId = 0) {
		this._bookEventGTMSrv.bookEventClick(args);
		this._bookEventBtnApiSrv.trackEventClicks(args.uniId, eventId, product);
	}
}
