import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SearchSvgComponent } from '@uc/shared/svg';
import {
	CourseSearchAutosuggestComponent,
	CourseSearchAutosuggestService,
} from '@uc/web/shared/feature/feature-course-search-autosuggest';
import { Subscription, debounceTime } from 'rxjs';

@Component({
	selector: 'uc-filter-search',
	standalone: true,
	imports: [ReactiveFormsModule, CourseSearchAutosuggestComponent, SearchSvgComponent],
	templateUrl: './filter-search.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSearchComponent implements OnInit, OnDestroy {
	@Input({ required: true }) searchTerm = '';
	@Input({ required: true }) parentInput!: string;
	@Input() placeholderText = 'Search';
	@Input() autoSuggestEnabled = false;
	@Output() searchTermEntered = new EventEmitter<string>();

	searchForm!: FormGroup;

	private _controlSub!: Subscription;

	constructor(private _autosuggestSrv: CourseSearchAutosuggestService) {}

	ngOnInit(): void {
		this.searchForm = new FormGroup({
			search: new FormControl(this.searchTerm),
		});

		this._controlSub = this.searchForm.controls['search'].valueChanges
			.pipe(debounceTime(500))
			.subscribe((value) => {
				this.searchTermEntered.emit(value);
			});
	}

	ngOnDestroy(): void {
		this._controlSub?.unsubscribe();
	}

	handleAutosuggestSelect(autosuggest: { searchTerm: string; degree?: string }): void {
		this.searchTermEntered.emit(autosuggest.searchTerm);
	}

	onAutoSuggestOpen(event: boolean) {
		if (this.parentInput === 'courseSearchFilter') {
			this._autosuggestSrv.autoSuggestOpen.set(event);
		}
	}
}
