<div class="pt-6">
	<div ucToggleFaq>
		<button
			(click)="onFilterState()"
			type="button"
			class="flex items-center justify-between w-full only-tab-focus"
			aria-label="Tabs"
		>
			<span
				class="text-base font-medium text-gray-500 capitalize pointer-events-none"
				role="presentation"
			>
				{{ filterTitle() }}
			</span>
			<div class="text-gray-500 pointer-events-none">
				<uc-plus-svg />
				<uc-minus-svg customStyles="hidden pointer-events-none" />
			</div>
		</button>
	</div>

	<div class="mt-4 rounded-md sr-only">
		<form [formGroup]="ucasForm" class="space-y-3">
			<div
				class="flex items-center justify-between w-full gap-2 text-gray-700 focus:outline-none"
			>
				<div>
					<label for="grade-min" class="sr-only">UCAS minimum points</label>
					<input
						formControlName="min"
						type="number"
						id="grade-min"
						placeholder="Min score"
						class="w-full p-2 placeholder-gray-400 border border-gray-300"
					/>
				</div>

				<div>
					<label for="grade-max" class="sr-only">UCAS maximum points</label>
					<input
						formControlName="max"
						type="number"
						id="grade-max"
						placeholder="Max score"
						class="w-full p-2 placeholder-gray-400 border border-gray-300"
					/>
				</div>
			</div>

			<uc-form-error
				[fieldError]="'Please fill in both fields.'"
				[formError]="formSubmitted && customError === 'required'"
			/>

			<uc-form-error
				[fieldError]="'No changes made'"
				[formError]="formSubmitted && customError === 'no-changes'"
			/>

			<uc-form-error
				[fieldError]="
					'The minimum score needs to be smaller than the maximum score.'
				"
				[formError]="formSubmitted && customError === 'range'"
			/>

			<uc-form-error
				[fieldError]="
					'Please provide numbers within the range of 1 - ' + maxScore + '.'
				"
				[formError]="formSubmitted && customError === 'invalid'"
			/>

			<button
				(click)="onSubmit()"
				type="button"
				class="w-full shadow-sm secondary-button btn-py-2"
			>
				Apply
			</button>
		</form>
	</div>

	<div class="w-full mt-6 border-b border-gray-500"></div>
</div>
