import { Injectable, signal } from '@angular/core';
import { SourceTrackingTypes } from '@uc/web/shared/data-models';

export type ModalType = 'internal' | 'external';

@Injectable({ providedIn: 'root' })
export class VisitWebsiteModalService {
	uniId = signal<number | null>(null);
	uniName = signal<string>('');
	privacyPolicyUrl = signal<string>('');
	externalLink = signal<string>('');
	componentName = signal<string>('');
	cleSearchOrderPosition = signal<number | null>(null);
	source = signal<string>('');
	sourceType = signal<SourceTrackingTypes>('');
	trackingEnabled = signal<boolean>(true);

	reset() {
		this.uniId.set(null);
		this.uniName.set('');
		this.privacyPolicyUrl.set('');
		this.externalLink.set('');
		this.componentName.set('');
		this.cleSearchOrderPosition.set(null);
		this.source.set('');
		this.sourceType.set('');
		this.trackingEnabled.set(true);
	}
}
