<div
	class="bg-white"
	[ngClass]="{
		'fixed top-0 left-0 z-[104] w-screen h-screen': isFocused(),
		'sm:static sm:w-auto sm:h-full sm:z-auto pt-3 sm:pt-0': isFocused(),
		'rounded-lg h-full sm:rounded-l-lg sm:rounded-none': isCourseSearchbar(),
	}"
>
	<label for="search" class="sr-only">Search</label>
	<div
		class="flex items-center justify-between sm:px-0 sm:h-full"
		[ngClass]="{ 'px-5': isFocused() }"
	>
		<div class="relative w-full sm:h-full">
			<div class="relative flex w-full sm:h-full">
				<div
					class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
				>
					<uc-search-svg customStyles="text-gray-400" />
				</div>
				<input
					#input
					[(ngModel)]="searchTerm"
					[value]="subject()"
					class="w-full h-full px-4 border border-gray-300 py-[11px] pl-12 text-base text-gray-600 placeholder-gray-400 rounded-lg"
					[ngClass]="{
						'filter-overlay-search': !isCourseSearchbar(),
						'sm:!border-white sm:focus:!border-white sm:focus:!shadow-none sm:focus:!ring-0':
							isCourseSearchbar(),
					}"
					type="search"
					placeholder="Search Courses"
					aria-label="Search"
					autocomplete="off"
					(input)="onSearch()"
					(keyup.enter)="onEnter({ searchTerm })"
					(focus)="onFocus()"
					(blur)="onBlur()"
				/>
			</div>
			@if (error()) {
				<small
					class="absolute z-[1000] text-sm text-red-600 -bottom-5"
					[ngClass]="{
						'sm:top-3 sm:-right-36': errorPosition() === 'default',
						'!text-red-300': context() === 'courseSearchBar',
					}"
					>{{ error() }}</small
				>
			}
		</div>

		@if (isFocused()) {
			<div class="flex items-center ml-4 sm:hidden">
				<button
					(click)="onClose()"
					class="h-[42px] inline-flex items-center justify-center p-2.5 text-gray-400 bg-gray-200 rounded-md hover:text-gray-500"
				>
					<uc-close-modal-svg customStyles="stroke-gray-900" />
				</button>
			</div>
		}
	</div>
	<uc-course-search-autosuggest
		[parentInput]="input"
		[position]="position"
		[resetSearchTerm]="resetSearchTerm()"
		[navigate]="navigate()"
		[showDegreeLevelOptions]="showDegreeLevelOptions()"
		(selectSuggestion)="onEnter($event)"
		(autoSuggestOpen)="onAutoSuggestOpen($event)"
		customStyles="md:w-[404px] {{ context() === 'filter' ? '!fixed' : '' }}"
	/>
</div>
