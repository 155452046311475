import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { PromoSlot } from '@uc/web/shared/data-models';
import { addContentToArrays } from '../utils/promo-slots.utils';
import {
	fetchPromoSlots,
	getClePromoSlotsData,
	getDefaultPromoSlotsData,
} from '@uc/web/shared/data-access';
import { AsyncPipe } from '@angular/common';
import { PromoSlotCardComponent } from '../components/promo-slot-card/promo-slot-card.component';
import { InAppBrowserService } from '@uc/web/core';

@Component({
	selector: 'uc-promo-slots',
	standalone: true,
	templateUrl: './promo-slots.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [AsyncPipe, PromoSlotCardComponent],
})
export class PromoSlotsComponent implements OnInit {
	headline = input.required<string>();
	section = input<1 | 2>(1);
	type = input<'clearing' | 'default'>('default');
	isInAppBrowser = input<boolean>(false);
	cards: PromoSlot[] = [];
	promoSlots$!: Observable<PromoSlot[] | null>;

	private _firstBatch: PromoSlot[] = [];
	private _secondBatch: PromoSlot[] = [];

	constructor(
		private _store: Store,
		private _isInAppBrowserSrv: InAppBrowserService,
	) {}

	ngOnInit(): void {
		if (this.section() === 1) {
			const platform = this._isInAppBrowserSrv.getPlatform();
			this._store.dispatch(fetchPromoSlots({ platform }));
		}
		const selector =
			this.type() === 'default' ? getDefaultPromoSlotsData : getClePromoSlotsData;

		this.promoSlots$ = this._store.select(selector).pipe(
			tap((cards) => {
				if (!cards || cards.length === 0) return;

				const copy: PromoSlot[] = structuredClone(cards);
				addContentToArrays(copy, 0, this._firstBatch);
				addContentToArrays(copy, 4, this._secondBatch);
				this.cards = this.section() === 1 ? this._firstBatch : this._secondBatch;
			}),
		);
	}
}
