import { ChangeDetectionStrategy, Component, Inject, input } from '@angular/core';
import {
	ConversionEventsService,
	ProspectusGTMService,
	abbreviateProduct,
} from '@uc/web/core';
import { Product } from '@uc/web/shared/data-models';
import { EngageCardCtaImgComponent } from '../engage-card-cta/components/engage-card-cta-img/engage-card-cta-img.component';
import { DOCUMENT, NgOptimizedImage } from '@angular/common';
import { ProspectusBtnComponent } from '../../buttons/prospectus-btn/prospectus-btn.component';
import { CdnPathPipe } from '@uc/utilities';
import { Router } from '@angular/router';
import { ProspectusModalService } from '../../modals/prospectus-modal/prospectus-modal.service';

@Component({
	selector: 'uc-engage-card-prospectus-cta',
	standalone: true,
	imports: [
		EngageCardCtaImgComponent,
		NgOptimizedImage,
		ProspectusBtnComponent,
		CdnPathPipe,
	],
	templateUrl: './engage-card-prospectus-cta.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngageCardProspectusCtaComponent {
	mainTitle = input.required<string>();
	description = input.required<string>();
	subTitle = input.required<string>();
	uniId = input.required<number>();
	externalProspectusUrl = input.required<string>();
	product = input.required<Product>();
	ugSearchOrderPosition = input.required<number | null>();
	privacyPolicyUrl = input.required<string>();
	private _window: Window & typeof globalThis;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private _router: Router,
		private _prospectusGTMSrv: ProspectusGTMService,
		private _conversionEventsSrv: ConversionEventsService,
		private _prospectusModalSrv: ProspectusModalService,
	) {
		this._window = this._document.defaultView as Window & typeof globalThis;
	}

	onProspectusClick() {
		this._prospectusGTMSrv.getProspectusClick(
			'Get Prospectus CTA',
			this.ugSearchOrderPosition(),
		);

		if (this.externalProspectusUrl()) {
			this._handleExternalLink();
		} else {
			this._handleInternalLink();
		}
	}

	private _handleExternalLink() {
		this._prospectusModalSrv.setProduct(this.product());
		this._window.open(this.externalProspectusUrl());
	}

	private _handleInternalLink() {
		const abbreviatedProduct = abbreviateProduct(this.product());
		this._conversionEventsSrv.prospectusOrder(1);
		this._router.navigate(['/order'], {
			queryParams: { uni: this.uniId(), product: abbreviatedProduct },
		});
	}
}
