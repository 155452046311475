import { Injectable, signal } from '@angular/core';
import { BookEvent } from '@uc/web/core';
import { Product } from '@uc/web/shared/data-models';

export type ModalType = 'internal' | 'external';

@Injectable({ providedIn: 'root' })
export class BookEventModalService {
	privacyPolicyUrl = signal<string | null>(null);
	externalLink = signal<string | null>(null);
	bookEventGTMArgs = signal<BookEvent | null>(null);
	eventId = signal<number | null>(null);
	product = signal<Product | null>(null);

	reset() {
		this.privacyPolicyUrl.set(null);
		this.externalLink.set(null);
		this.bookEventGTMArgs.set(null);
		this.eventId.set(null);
		this.product.set(null);
	}
}
