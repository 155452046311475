import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
	FeaturedPostgraduate,
	FeaturedUndergraduate,
	Product,
	ProductEnum,
} from '@uc/web/shared/data-models';
import { RequestBtnService } from './request-btn.service';
import { abbreviateProduct, RequestInfoGTMService } from '@uc/web/core';

@Component({
	selector: 'uc-request-btn',
	standalone: true,
	templateUrl: './request-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RouterModule],
})
export class RequestBtnComponent {
	product = input.required<Product | null>();
	params = input<string | number | undefined>();
	componentName = input<string>('');
	customStyles = input<string>('w-full');
	buttonText = input<string>('Request info');
	courseName = input<string>('');
	ugSearchOrderPosition = input<number | null>(null);
	isFeaturedPostgraduate = input<FeaturedPostgraduate>();
	isFeaturedUndergraduate = input<FeaturedUndergraduate>();

	constructor(
		private _router: Router,
		private _requestInfoGTMSrv: RequestInfoGTMService,
		private _requestBtnSrv: RequestBtnService,
	) {}

	onRequestInfoClick() {
		this._requestInfoGTMSrv.requestInfoClick(
			this.componentName(),
			this.product() === ProductEnum.Undergraduate || !this.product()
				? this.ugSearchOrderPosition()
				: null,
		);
		this._requestBtnSrv.setCourseName(this.courseName());

		// both UG + PG feature
		if (
			this.isFeaturedPostgraduate() &&
			this.isFeaturedUndergraduate() &&
			!this.product()
		) {
			this._router.navigate(['/request/select'], {
				queryParams: { uni: this.params() },
			});
		} else if (
			// only PG feature
			!this.isFeaturedUndergraduate() &&
			this.isFeaturedPostgraduate() &&
			!this.product()
		) {
			this._router.navigate(['/request'], {
				queryParams: {
					uni: this.params(),
					product: abbreviateProduct(ProductEnum.Postgraduate),
				},
			});
		} else if (
			// only UG feature
			!this.isFeaturedPostgraduate() &&
			this.isFeaturedUndergraduate() &&
			!this.product()
		) {
			this._router.navigate(['/request'], {
				queryParams: {
					uni: this.params(),
					product: abbreviateProduct(ProductEnum.Undergraduate),
				},
			});
		} else if (this.product()) {
			this._router.navigate(['/request'], {
				queryParams: {
					uni: this.params(),
					product: abbreviateProduct(this.product()!),
				},
			});
		} else {
			this._router.navigate(['/request/select'], {
				queryParams: {
					uni: this.params(),
				},
			});
		}
	}
}
