import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentApiService } from '@uc/utilities';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BookmarkApiService {
	constructor(
		private _http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	saveUni(id: number) {
		const params = {
			id: id,
			observe: 'response',
		};
		return lastValueFrom(
			this._http.post<{ message: string }>(
				this._apiSrv.API_URL + 'student/favourite-universities',
				params,
				{ transferCache: false },
			),
		);
	}

	deleteUni(id: number) {
		const params = {
			id: id,
			observe: 'response',
		};
		return lastValueFrom(
			this._http.delete<{ message: string }>(
				this._apiSrv.API_URL + 'student/favourite-universities',
				{
					body: params,
					observe: 'response',
				},
			),
		);
	}

	saveCourse(id: string) {
		return lastValueFrom(
			this._http.post<{ message: string }>(
				this._apiSrv.API_URL + 'student/favourite-courses',
				{
					id,
					observe: 'response',
				},
				{ transferCache: false },
			),
		);
	}

	deleteCourse(id: string) {
		return lastValueFrom(
			this._http.delete<{ message: string }>(
				this._apiSrv.API_URL + 'student/favourite-courses',
				{
					body: { id },
					observe: 'response',
				},
			),
		);
	}
}
