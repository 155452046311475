<div
	aria-hidden="true"
	aria-label="Book Event"
	class="relative mx-auto bg-white rounded-lg shadow-lg max-w-max"
>
	<uc-close-modal />
	<div class="flex flex-col items-center justify-center p-6 max-w-[400px]">
		<div class="flex flex-col items-center justify-center text-center">
			<div class="p-2 rounded-full bg-red-50">
				<div class="w-full h-full p-3 bg-red-100 rounded-full">
					<uc-calendar-svg customStyles="text-red-900" />
				</div>
			</div>
			<h3 class="mt-5 mb-2 text-lg font-semibold leading-7 text-gray-900">
				Continue to Event
			</h3>
			<p class="mb-8 text-base leading-6 text-gray-500">
				Clicking the link will take you to the university's website. To help with
				this booking, we will share your profile information with them
			</p>
			<a
				[href]="externalLink"
				(click)="trackEventClick()"
				class="inline-flex items-center justify-center rounded-md book-event-btn whitespace-nowrap"
				target="_blank"
				rel="sponsored noopener"
				data-test-id="book-event-btn"
			>
				Book Event
			</a>
			@if (privacyPolicy) {
				<a
					[attr.href]="privacyPolicy"
					class="block mt-3 underline blue-link"
					rel="noopener"
					target="_blank"
					>See University Privacy Policy
				</a>
			}
		</div>
	</div>
</div>
