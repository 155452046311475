import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	computed,
	ElementRef,
	HostListener,
	Inject,
	input,
	model,
	OnInit,
	output,
	PLATFORM_ID,
	signal,
	viewChild,
} from '@angular/core';
import { CloseModalSvgComponent, SearchSvgComponent } from '@uc/shared/svg';
import { CourseSearchAutosuggestComponent } from '@uc/web/shared/feature/feature-course-search-autosuggest';
import { FormsModule } from '@angular/forms';
import { isPlatformBrowser, NgClass } from '@angular/common';

@Component({
	selector: 'uc-course-finder',
	standalone: true,
	templateUrl: './course-finder.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgClass,
		FormsModule,
		SearchSvgComponent,
		CourseSearchAutosuggestComponent,
		CloseModalSvgComponent,
	],
})
export class CourseFinderComponent implements OnInit, AfterViewInit {
	subject = input('', {
		transform: (value: string | undefined) => value?.replace(/-/g, ' ') || '',
	});
	resetSearchTerm = input<boolean>();
	showDegreeLevelOptions = input<boolean>(true);
	navigate = input<boolean>(false);
	context = input<'default' | 'courseSearchBar' | 'filter'>('default');
	errorPosition = input<'default' | 'bottom'>('default');
	disableError = input<boolean>(false);
	resetErrorOnBlur = input<boolean>(false);
	error = model<string>('');

	autosuggest = output<{ searchTerm: string; degree?: string }>();
	searchChange = output<string>();

	autosuggestOpen = signal(false);
	isFocused = signal<boolean>(false);

	isCourseSearchbar = computed(() => this.context() === 'courseSearchBar');

	inputElem = viewChild<ElementRef<HTMLInputElement>>('input');

	position: { top: number; left: number } | undefined;
	searchTerm = '';

	@HostListener('window:scroll', ['$event']) onScroll() {
		if (this.context() !== 'filter') return;
		this.position = this.getElementPosition();
	}

	constructor(@Inject(PLATFORM_ID) private _platform: object) {}

	ngOnInit(): void {
		this.searchTerm = this.subject() ?? '';
	}

	ngAfterViewInit() {
		if (this.context() !== 'filter') return;
		this.position = this.getElementPosition();
	}

	onEnter(autosuggest: { searchTerm: string; degree?: string }) {
		if (!this.searchTerm && !this.disableError()) {
			this.error.set('Please enter a subject');
			return;
		}

		this.isFocused.set(false);
		this.autosuggest.emit(autosuggest);
	}

	onSearch() {
		this.searchChange.emit(this.searchTerm);

		if (this.searchTerm) {
			this.error.set('');
		}
	}

	onFocus() {
		this.isFocused.set(true);
	}

	onBlur() {
		if (this.resetErrorOnBlur()) {
			this.error.set('');
		}
	}

	onClose() {
		this.isFocused.set(false);
		this.error.set('');
	}

	onAutoSuggestOpen(open: boolean) {
		this.autosuggestOpen.set(open);
	}

	getElementPosition() {
		if (!isPlatformBrowser(this._platform)) return;

		const elem = this.inputElem()?.nativeElement.getBoundingClientRect();
		if (!elem) return;

		return {
			top: elem.top + elem.height,
			left: elem.left,
		};
	}
}
