import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomCookieService } from '@uc/shared/authentication';
import { configureSource, EnvironmentApiService } from '@uc/utilities';
import { SourceTrackingTypes } from '@uc/web/shared/data-models';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CallUniBtnApiService {
	constructor(
		private _http: HttpClient,
		private _customCookieSrv: CustomCookieService,
		private _apiSrv: EnvironmentApiService,
	) {}

	/**
	 * logs all the clearing clicks on the call uni buttons
	 * @returns object { result: 1 | 0 }
	 */
	trackClick(uniId: number, source: string, sourceType: SourceTrackingTypes = 'uni') {
		const userId = this._customCookieSrv.get('user_id');
		const params = {
			uid: uniId,
			id: userId ? userId : 0,
			source: configureSource(source, sourceType),
		};

		lastValueFrom(
			this._http.post(this._apiSrv.PHP_URL + 'Calls/uc_uni_web_call.php', params),
		).then(console.log);
	}
}
