import { Injectable } from '@angular/core';
import { Product } from '@uc/web/shared/data-models';
import { BehaviorSubject } from 'rxjs';

export type ModalType = 'internal' | 'external';

@Injectable({ providedIn: 'root' })
export class ProspectusModalService {
	private _uniForProspectusOrder = new BehaviorSubject<number | string | undefined>(0);
	uniForProspectusOrder$ = this._uniForProspectusOrder.asObservable();

	private _privacyPolicyUrl = new BehaviorSubject<string>('');
	privacyPolicyUrl$ = this._privacyPolicyUrl.asObservable();

	private _modalType = new BehaviorSubject<ModalType>('external');
	modalType$ = this._modalType.asObservable();

	private _externalLinks = new BehaviorSubject<string[]>([]);
	externalLinks$ = this._externalLinks.asObservable();

	private _componentName = new BehaviorSubject<string>('');
	componentName$ = this._componentName.asObservable();

	private _ugSearchOrderPosition = new BehaviorSubject<number | null>(null);
	ugSearchOrderPosition$ = this._ugSearchOrderPosition.asObservable();

	private _product = new BehaviorSubject<Product | null>(null);
	product$ = this._product.asObservable();

	setUniForProspectusOrder(uniId: number | string | undefined) {
		this._uniForProspectusOrder.next(uniId);
	}

	setPrivacyPolicyUrl(url: string) {
		this._privacyPolicyUrl.next(url);
	}

	setModalType(type: ModalType) {
		this._modalType.next(type);
	}

	setExternalLinks(links: string[]) {
		this._externalLinks.next(links);
	}

	setComponentName(name: string) {
		this._componentName.next(name);
	}

	setUgSearchOrderPosition(order: number | null) {
		this._ugSearchOrderPosition.next(order);
	}

	setProduct(product: Product | null) {
		this._product.next(product);
	}

	turnUniIdIntoNumber(uniId: number | string | undefined): number {
		if (typeof uniId === 'string') {
			const parsedString = parseInt(uniId);
			return !isNaN(parsedString) ? parsedString : 0;
		} else if (typeof uniId === 'number') {
			return uniId;
		} else {
			return 0;
		}
	}
}
