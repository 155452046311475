@if (externalUrl()) {
	@if (userId) {
		<button
			(click)="openModal()"
			[ngClass]="customStyles()"
			class="inline-flex items-center justify-center rounded-md book-event-btn whitespace-nowrap"
		>
			{{ buttonText() }}
		</button>
	} @else {
		<a
			[attr.href]="externalUrl()"
			(click)="onBookEventClick()"
			[ngClass]="customStyles()"
			class="inline-flex items-center justify-center rounded-md book-event-btn whitespace-nowrap"
			target="_blank"
			rel="sponsored noopener"
			data-test-id="book-event-btn"
		>
			{{ buttonText() }}
		</a>
	}
}
