import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	input,
	OnInit,
	output,
	signal,
	untracked,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownDirective } from '@uc/utilities';
import { ArrowRightSvgComponent, ArrowDownSvgComponent } from '@uc/shared/svg';
import { DegreeLevelOption } from '@uc/web/shared/data-models';
import { CourseFinderComponent } from '../course-finder/course-finder.component';
import { DegreeLevelService } from '@uc/web/shared/data-access';

@Component({
	selector: 'uc-course-search-bar',
	standalone: true,
	templateUrl: './course-search-bar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		DropdownDirective,
		FormsModule,
		ArrowRightSvgComponent,
		ArrowDownSvgComponent,
		CourseFinderComponent,
	],
})
export class CourseSearchBarComponent implements OnInit {
	options = input.required<DegreeLevelOption[]>();
	subject = input('', {
		transform: (value: string | undefined) => value?.replace(/-/g, ' '),
	});
	degree = input<string>();
	autoSuggestEnabled = input(false);
	disableError = input(false);

	enter = output<{ searchTerm: string; degree?: string }>();

	selectedOption = computed(() => this._degreeLevelSrv.selectedDegreeLevel());
	error = signal<string>('');

	searchTerm = '';

	constructor(private _degreeLevelSrv: DegreeLevelService) {
		effect(() => {
			const degree = this.degree();

			const selected =
				this.options().find((option) => degree && option.link.includes(degree)) ??
				this.options()[0];

			untracked(() => {
				this._degreeLevelSrv.selectedDegreeLevel.set(selected);
			});
		});
	}

	ngOnInit(): void {
		this.searchTerm = this.subject() ?? '';
	}

	onDegreeLevelChange(option: DegreeLevelOption): void {
		this._degreeLevelSrv.selectedDegreeLevel.set(option);
	}

	onSearch(autosuggest: { searchTerm: string }): void {
		if (!autosuggest.searchTerm) {
			this.error.set('Please enter a subject');
			return;
		}
		this.enter.emit({
			searchTerm: autosuggest?.searchTerm,
			degree: this.selectedOption()?.link,
		});
	}

	onSearchChange(searchTerm: string) {
		this.error.set('');
		this.searchTerm = searchTerm;
	}
}
