<section class="bg-white divider">
	<div class="flex flex-col">
		<div class="relative overflow-hidden">
			<button (click)="onProspectusClick()" class="block w-full h-full rounded-2xl">
				<uc-engage-card-cta-img backgroundImageClass="bg-cta-prospectus" />
			</button>

			<div class="relative pt-6 mx-auto md:py-12 max-w-7xl">
				<div class="lg:px-5 md:ml-auto md:w-1/2 md:pl-8 lg:pl-16">
					<div class="flex items-center gap-3">
						<img
							width="20"
							height="20"
							alt=""
							[ngSrc]="'/assets/img/book2.svg' | cdn"
						/>
						<p class="font-semibold text-gray-500 uppercase">
							{{ subTitle() }}
						</p>
					</div>
					<p class="flex flex-wrap-reverse mt-2 tracking-tight">
						<button (click)="onProspectusClick()">
							<span class="text-2xl font-bold md:text-3xl">
								{{ mainTitle() }}
							</span>
						</button>
					</p>
					<p class="mt-4 mb-8 text-base text-gray-500">
						{{ description() }}
					</p>
					<uc-prospectus-btn
						[params]="uniId()"
						[link]="externalProspectusUrl()"
						componentName="Get Prospectus CTA"
						customStyles="w-full md:w-auto"
						[privacyPolicyUrl]="privacyPolicyUrl()"
						[product]="product()"
						[showModal]="true"
						[ugSearchOrderPosition]="ugSearchOrderPosition()"
					/>
				</div>
			</div>
		</div>
	</div>
</section>
