<section
	class="absolute z-10 hidden pt-6 pb-12 bg-transparent left-1/2 top-1/2 md:block sm:py-12 px-14"
	style="transform: translateY(-50%)"
>
	<img
		[attr.priority]="true"
		[ngSrc]="'/assets/img/' + imageName() | cdn"
		width="524"
		height="280"
		alt=""
		class="rounded-2xl"
		priority="true"
	/>
	<h2 class="mt-4 text-lg font-bold leading-7 text-gray-900">
		{{ heading() }}
	</h2>
	<p class="text-base leading-6 text-gray-500">
		{{ description() }}
	</p>
</section>
