@if (externalUrl()) {
	@if (userId && trackingEnabled()) {
		<button
			[ngClass]="customStyles()"
			(click)="openModal()"
			class="inline-block blue-button focus:outline-none whitespace-nowrap"
		>
			{{ buttonText() }}
		</button>
	} @else {
		<a
			(click)="onVisitWebsiteClick(); onVisitWebsiteConversion()"
			[attr.href]="externalUrl()"
			target="_blank"
			[ngClass]="customStyles()"
			class="inline-block blue-button focus:outline-none whitespace-nowrap"
			rel="sponsored noopener"
		>
			{{ buttonText() }}
		</a>
	}
}
