<div class="pt-6">
	<div ucToggleFaq>
		<button
			(click)="onFilterState()"
			type="button"
			class="flex items-center justify-between w-full only-tab-focus"
			aria-label="Tabs"
		>
			<span
				class="text-base font-medium text-gray-500 capitalize pointer-events-none"
				role="presentation"
			>
				{{ filterTitle() }}
			</span>
			<div class="text-gray-500 pointer-events-none">
				<uc-plus-svg />
				<uc-minus-svg customStyles="hidden pointer-events-none" />
			</div>
		</button>
	</div>

	<fieldset class="mt-4 space-y-3 rounded-md sr-only">
		@for (option of options(); track option.name) {
			<div
				class="flex items-center text-gray-700 focus:outline-none whitespace-nowrap"
			>
				<label class="flex items-center cursor-pointer">
					<input
						[name]="filterTitle()"
						[type]="type()"
						[checked]="option.checked"
						class="relative w-4 h-4 mr-2 cursor-pointer opacity-80 top-px"
						(change)="onSelect(option)"
					/>
					<span class="text-sm font-medium text-gray-500">
						{{ option.name }}
						@if (option.count) {
							({{ option.count }})
						}
					</span>
				</label>
				@for (tooltip of tooltips(); track tooltip.filterLabel) {
					@if (
						tooltip.filterLabel.toLowerCase() === option.name.toLowerCase()
					) {
						<uc-information-circle-solid-svg
							ucTooltip
							[tooltipTitle]="tooltip.tooltip"
							class="block ml-1"
						/>
					}
				}
			</div>
		}
	</fieldset>

	<div class="w-full mt-6 border-b border-gray-500"></div>
</div>
