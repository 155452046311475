<section
	aria-hidden="true"
	aria-label="Visit Website"
	class="relative mx-auto bg-white rounded-lg shadow-lg max-w-max"
>
	<uc-close-modal />
	<div class="flex flex-col items-center justify-center p-6 max-w-[400px]">
		<div class="flex flex-col items-center justify-center text-center">
			<div class="p-2 rounded-full bg-uc-blue-50">
				<div class="w-full h-full p-3 rounded-full bg-uc-blue-100">
					<uc-external-link-svg class="w-6 h-6 text-uc-blue-900" />
				</div>
			</div>
			<h3 class="mt-5 mb-2 text-lg font-semibold leading-7 text-gray-900">
				Visit {{ uniName }}'s Website
			</h3>
			<p class="mb-8 text-base leading-6 text-gray-500">
				By visiting this university's website, you're opting in for us to share
				your data with them so they can verify your visit or contact you.
			</p>
			<a
				[href]="externalLink"
				(click)="trackClick()"
				class="inline-flex items-center justify-center rounded-md blue-button whitespace-nowrap"
				target="_blank"
				rel="sponsored noopener"
			>
				Visit Website
			</a>
			@if (privacyPolicyUrl) {
				<a
					[attr.href]="privacyPolicyUrl"
					class="block mt-3 underline blue-link"
					rel="noopener"
					target="_blank"
					>See University Privacy Policy
				</a>
			}
		</div>
	</div>
</section>
