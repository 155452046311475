import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CalendarSvgComponent } from '@uc/shared/svg';
import { Product } from '@uc/web/shared/data-models';

import { BookEventBtnComponent } from '../buttons/book-event-btn/book-event-btn.component';

@Component({
	selector: 'uc-no-open-days',
	standalone: true,
	imports: [CalendarSvgComponent, BookEventBtnComponent],
	templateUrl: './no-open-days.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoOpenDaysComponent {
	uniName = input.required<string>();
	uniId = input.required<number>();
	externalUrl = input.required<string>();
	product = input.required<Product>();
	isFeatured = input.required<boolean>();
	ugSearchOrderPosition = input.required<number | null>();
	privacyPolicyUrl = input.required<string | null>();
}
