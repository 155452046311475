<div class="flex items-center flex-1 mt-8 md:rounded-lg md:bg-white">
	<div class="w-full">
		<div class="relative w-full text-lg">
			<div
				class="absolute left-0 flex items-center px-6 pt-3.5 pointer-events-none md:inset-y-0 md:pt-0"
			>
				<uc-search-svg customStyles="text-gray-400" />
			</div>
			<input
				#searchBox
				type="search"
				id="inputSearchHome"
				(keydown.enter)="navigateToPage()"
				[(ngModel)]="searchValue"
				[placeholder]="placeholder()"
				class="border-0 w-full md:w-[calc(100%-21rem)] py-3 md:py-5 pl-16 pr-8 text-base text-gray-700 bg-white rounded-lg sm:py-4.5 focus:outline-none focus:border-gray-400 focus:ring-0"
				autocomplete="off"
			/>
			@if (autoSuggestEnabled()) {
				<uc-course-search-autosuggest
					[parentInput]="searchBox"
					customStyles="w-full md:w-[506px]"
					(selectSuggestion)="searchValue = $event.searchTerm; navigateToPage()"
				/>
			}
			<div
				ucDropdown
				aria-labelledby="menu-button"
				class="mt-4 md:mt-0 md:absolute right-0 w-full -top-16 md:top-1.5 md:right-36 bottom-2 md:w-56"
			>
				<button
					class="inline-flex justify-between w-full px-4 py-4 text-sm font-medium text-gray-700 capitalize bg-white rounded-lg md:border-gray-200 md:rounded-none md:border-l hover:bg-gray-50"
					aria-expanded="true"
					aria-haspopup="true"
				>
					{{ selectedProduct }}
					<uc-arrow-down-svg customStyles="ml-2 -mr-1" />
				</button>
				<div
					class="absolute left-0 w-full bg-white rounded-md shadow-lg home-dropdown-content md:mt-3 md:w-56 md:rounded-t-none ring-1 ring-black ring-opacity-5 focus:outline-none md:level-dropdown"
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="menu-button"
					tabindex="-1"
				>
					<div class="py-1" role="none">
						@for (product of products; track product) {
							<div
								class="flex items-center w-full px-4 text-gray-700 capitalize cursor-pointer hover:bg-gray-50"
								(click)="onChangeStudyType(product)"
							>
								<a
									class="block py-2 ml-2 text-gray-700 text-md"
									role="menuitem"
									tabindex="-1"
									>{{ product }}</a
								>
							</div>
						}
					</div>
				</div>
			</div>
			<button
				type="submit"
				class="bottom-0 right-0 flex items-center justify-center w-full py-3 mt-4 text-xl font-semibold bg-white border-l-2 rounded-lg border-l-uc-blue-900 text-uc-blue-900 md:mt-0 md:absolute top-16 md:top-0 h-11 md:h-auto md:rounded-r-md md:rounded-none hover:bg-gray-100 md:w-36"
				id="buttonHomeSearch"
				(click)="navigateToPage()"
			>
				Search
				<uc-arrow-right-svg customStyles="w-4 h-4 sm:w-6 sm:h-6 ml-2.5" />
			</button>
		</div>
	</div>
</div>
