import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { NgClass } from '@angular/common';
import { Product, ProductEnum } from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-study-level-select',
	standalone: true,
	imports: [NgClass],
	templateUrl: './study-level-select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyLevelSelectComponent {
	studyLevel = input.required<Product | undefined>();
	description = input.required<string>();
	fieldLabel = input<string>('Select Study Level');
	labelDesign = input<string>('text-gray-700');

	studyLevelChange = output<Product>();

	readonly undergraduate = ProductEnum.Undergraduate;
	readonly postgraduate = ProductEnum.Postgraduate;

	onStudyLevel(studyLevel: Product) {
		this.studyLevelChange.emit(studyLevel);
	}
}
