<div [ngClass]="{ 'border-b border-gray-200': displayBottomBorder() }">
	<h3 class="text-xl font-semibold text-black">Your university</h3>
	<uc-leads-uni-details-image
		[coverImage]="coverImage()"
		[uniName]="uniName()"
		[uniLogo]="uniLogo()"
		[recommendedPercentage]="recommendedPercentage()"
		[courseCount]="courseCount()"
		[isClearingCourses]="isClearingCourses()"
	/>
</div>
<!-- General content -->
<ng-content />
<!-- Privacy Policy -->
<ng-content selector="[privacy-policy]" />
