import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FeaturedPostgraduate, FeaturedUndergraduate } from '@uc/web/shared/data-models';

import { Product } from '@uc/web/shared/data-models';
import { ProspectusBtnComponent } from '../buttons/prospectus-btn/prospectus-btn.component';
import { RequestBtnComponent } from '../buttons/request-btn/request-btn.component';
import { VisitWebsiteBtnComponent } from '../buttons/visit-website-btn/visit-website-btn.component';

@Component({
	selector: 'uc-cta-university-btns',
	standalone: true,
	templateUrl: './cta-university-btns.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [VisitWebsiteBtnComponent, RequestBtnComponent, ProspectusBtnComponent],
})
export class CtaUniversityBtnsComponent {
	uniName = input.required<string>();
	product = input.required<Product | null>();
	ugSearchOrderPosition = input.required<number | null>();
	uniId = input.required<number>();
	uniSlug = input.required<string>();
	externalUrl = input<string>('');
	externalUgProspectusUrl = input<string>('');
	externalPgProspectusUrl = input<string>('');
	isFeaturedPostgraduate = input<FeaturedPostgraduate>(false);
	isFeaturedUndergraduate = input<FeaturedUndergraduate>(false);
	privacyPolicyUrl = input<string>('');
	externalProspectusUrl = input<string>('');
}
