import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { CtaCard } from '../clearing/clearing-search/clearing-search.models';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-static-card',
	standalone: true,
	templateUrl: './static-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CdnPathPipe, CommonModule, NgOptimizedImage, RouterModule],
})
export class StaticCardComponent {
	content = input.required<CtaCard>();
	customHeight = input<string>('lg:h-44');
	cardClick = output<boolean>();

	onClick() {
		this.cardClick.emit(true);
	}
}
