import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ConversionEventsService } from '@uc/web/core';
import { SourceTrackingTypes } from '@uc/web/shared/data-models';
import { CustomCookieService } from '@uc/shared/authentication';
import { ModalService } from '@uc/shared/ui';
import { ClearingVisitWebsiteModalComponent } from '../../modals/clearing-visit-website-modal/clearing-visit-website-modal.component';
import { VisitWebsiteModalService } from '../../modals/clearing-visit-website-modal/clearing-visit-website-modal.service';
import { ClearingVisitWebsiteBtnService } from './clearing-visit-website-btn.service';

@Component({
	selector: 'uc-clearing-visit-website-btn',
	standalone: true,
	imports: [NgClass],
	templateUrl: './clearing-visit-website-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearingVisitWebsiteBtnComponent {
	externalUrl = input.required<string | undefined>();
	uniId = input.required<number | null>();
	uniName = input.required<string>();
	source = input.required<string>(); // uni name or course name
	sourceType = input.required<SourceTrackingTypes>();
	cleSearchOrderPosition = input.required<number | null>();
	componentName = input.required<string>();
	privacyPolicyUrl = input.required<string | null>();
	buttonText = input('Visit Website');
	customStyles = input('w-auto');
	trackingEnabled = input(true);
	visitWebsiteClick = output<void>();

	userId = this._customCookieSrv.get('user_id');

	constructor(
		private _conversionEventsSrv: ConversionEventsService,
		private _customCookieSrv: CustomCookieService,
		private _modalSrv: ModalService,
		private _visitWebsiteModalSrv: VisitWebsiteModalService,
		private _clearingVisitWebsiteBtnSrv: ClearingVisitWebsiteBtnService,
	) {}

	// GTM Event
	onVisitWebsiteClick() {
		this._clearingVisitWebsiteBtnSrv.onClearingVisitWebsiteClick(
			this.uniId(),
			this.uniName(),
			this.componentName(),
			this.cleSearchOrderPosition(),
			this.source(),
			this.sourceType(),
			this.trackingEnabled(),
		);
	}

	// Google Ads Conversion
	onVisitWebsiteConversion() {
		this._conversionEventsSrv.visitWebsite();
	}

	openModal() {
		this._visitWebsiteModalSrv.externalLink.set(this.externalUrl() ?? '');
		this._visitWebsiteModalSrv.uniName.set(this.uniName());
		this._visitWebsiteModalSrv.uniId.set(this.uniId());
		this._visitWebsiteModalSrv.source.set(this.source());
		this._visitWebsiteModalSrv.sourceType.set(this.sourceType());
		this._visitWebsiteModalSrv.cleSearchOrderPosition.set(
			this.cleSearchOrderPosition(),
		);
		this._visitWebsiteModalSrv.componentName.set(this.componentName());
		this._visitWebsiteModalSrv.privacyPolicyUrl.set(this.privacyPolicyUrl() ?? '');
		this._visitWebsiteModalSrv.trackingEnabled.set(this.trackingEnabled());

		this._modalSrv.setContent(ClearingVisitWebsiteModalComponent);
		this._modalSrv.show();
	}
}
