<div
	class="flex justify-between w-full py-4 bg-white sm:border sm:border-gray-200 sm:rounded-lg sm:shadow-sm sm:py-0"
>
	<div class="flex justify-between rounded-lg w-full sm:w-[calc(100%-14rem)]">
		<div class="flex items-center sm:py-6 sm:pl-6">
			@if (date()) {
				<div
					class="sm:p-3 sm:border sm:border-gray-200 sm:rounded-md sm:max-w-max"
				>
					<p class="hidden text-3xl font-bold text-center sm:block">
						{{ date() | date: 'd' }}
					</p>
					<p
						class="hidden text-sm text-center uppercase sm:block whitespace-nowrap"
					>
						{{ date() | date: 'MMM, YYY' }}
					</p>
					@if (uniLogo()) {
						<div class="relative w-12 h-12 mr-3 sm:w-16 sm:h-16 sm:hidden">
							<img
								class="px-1 py-3 border rounded-2xl"
								[ngSrc]="uniLogo()!"
								fill
								[attr.alt]="uniName()"
							/>
						</div>
					}
				</div>
			}
			<div class="flex flex-col sm:px-4 sm:mt-0">
				<h3 class="-mb-1 text-lg font-medium">
					<a [routerLink]="['/universities', slug()]">
						<span class="hidden sm:inline-block">{{ uniName() }}</span>
						<span class="inline-block sm:hidden">{{
							date() | date: 'd MMM YYY'
						}}</span>
					</a>
				</h3>
				@if (description()) {
					<p class="mt-2 text-sm text-gray-500 capitalize">
						{{ description() | abbrProduct }}
					</p>
				}
				@if (subtitle()) {
					<p class="text-sm text-gray-400 capitalize">
						{{ subtitle()! | ucEntities }}
					</p>
				}
			</div>
		</div>
	</div>
	@if (externalUrl()) {
		<div
			class="relative flex items-center justify-center sm:px-6 sm:border-gray-200 sm:border-dashed sm:py-6 sm:w-56"
			[ngClass]="{
				'sm:border-l': externalUrl(),
			}"
		>
			<uc-book-event-btn
				class="w-full sm:w-auto"
				[externalUrl]="externalUrl()"
				[uniId]="uniId()"
				[eventType]="eventType()"
				[date]="date()"
				customStyles="w-full sm:w-auto event-ticket event-ticket-white mx-auto btn-px-overwrite btn-py-overwrite"
				customStyles="w-full sm:w-auto event-ticket event-ticket-white mx-auto"
				[buttonText]="'Book Event' | abbrButtonText: 'Book'"
				[componentName]="componentName()"
				[ugSearchOrderPosition]="ugSearchOrderPosition()"
				[product]="product()"
				[eventId]="id()"
				[uniName]="uniName()"
				[privacyPolicyUrl]="privacyPolicyUrl()"
			/>
		</div>
	}
</div>
