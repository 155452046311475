import { DeepReadonly, ProductEnum } from '@uc/web/shared/data-models';
import { SelectProductOption } from './select-product-form.models';

export const PRODUCT_OPTIONS: DeepReadonly<SelectProductOption>[] = [
	{
		product: ProductEnum.Undergraduate,
		title: 'Undergraduate student',
		description:
			"You're looking to apply for your first degree, whether it's a Bachelor's, HNC, HND etc.",
	},
	{
		product: ProductEnum.Postgraduate,
		title: 'Postgraduate student',
		description: 'With a bachelor’s degree, you’re now seeking an advanced degree.',
	},
];

export const PRODUCT_KEYS = {
	undergraduate: 'ug',
	postgraduate: 'pg',
};
