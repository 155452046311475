<fieldset class="flex flex-col gap-5 py-6 mb-6 -mt-2 border-t border-b">
	@for (option of productOptions; track option) {
		<label
			class="p-4 border cursor-pointer rounded-xl"
			[ngClass]="{
				'border-transparent outline outline-2 outline-uc-blue-200 bg-uc-blue-50':
					isProductSelected(option.product),
			}"
			[attr.data-test-id]="option.product + '-select-button'"
		>
			<input
				#checkbox
				name="level-select"
				class="relative w-4 h-4 mr-2 cursor-pointer opacity-80 top-px"
				type="radio"
				(change)="selectProduct(option.product)"
				[checked]="isProductSelected(option.product)"
			/>
			<span
				class="text-base font-medium leading-6"
				[ngClass]="{
					'text-gray-700': !isProductSelected(option.product),
					'text-uc-blue-900': isProductSelected(option.product),
				}"
			>
				{{ option.title }}
			</span>
			<span
				class="block text-base leading-6 ml-7"
				[ngClass]="{
					'text-gray-500': !isProductSelected(option.product),
					'text-uc-blue-900': isProductSelected(option.product),
				}"
			>
				{{ option.description }}
			</span>
		</label>
	}
</fieldset>

<uc-form-error [fieldError]="error()" [formError]="isError()" />

<button
	(click)="setProduct()"
	type="button"
	class="flex items-center justify-center w-full gap-2"
	[ngClass]="buttonStyles()"
	data-test-id="set-product-button"
>
	<span class="text-base font-bold">Next</span>
	<uc-arrow-right-svg />
</button>
