import { Component, inject, OnDestroy } from '@angular/core';
import { BookEventModalService } from './book-event-modal.service';
import { CloseModalComponent, ModalService } from '@uc/shared/ui';
import { BookEventBtnService } from '../../buttons/book-event-btn/book-event-btn.service';
import { CalendarSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-book-event-modal',
	standalone: true,
	imports: [CloseModalComponent, CalendarSvgComponent],
	templateUrl: './book-event-modal.component.html',
})
export class BookEventModalComponent implements OnDestroy {
	private _bookEventModalService = inject(BookEventModalService);
	private _bookEventBtnSrv = inject(BookEventBtnService);
	private _modalSrv = inject(ModalService);

	externalLink = this._bookEventModalService.externalLink();
	privacyPolicy = this._bookEventModalService.privacyPolicyUrl();

	ngOnDestroy(): void {
		this._bookEventModalService.reset();
	}

	trackEventClick() {
		const args = this._bookEventModalService.bookEventGTMArgs();
		const product = this._bookEventModalService.product();
		const eventId = this._bookEventModalService.eventId();

		if (args && product && eventId) {
			this._bookEventBtnSrv.trackEventClick(args, product, eventId);
		}

		this._modalSrv.close();
	}
}
